/* Contact.css */

.contact-section {
    @apply flex flex-col items-center justify-center p-8 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100;
}

.contact-header {
    @apply text-4xl font-bold mb-4;
}

.contact-form {
    @apply w-full max-w-lg mb-4;
}

.contact-form input,
.contact-form textarea {
    @apply w-full px-4 py-2 border border-gray-400 dark:border-gray-600 rounded focus:outline-none focus:border-yellow-500 dark:bg-gray-700 dark:text-gray-100;
    border: 1px solid #A08475;
    color: #A08475;
}

.contact-form button {
    @apply bg-yellow-400 text-gray-800 font-bold py-2 px-4 rounded hover:bg-yellow-500;
    background-color: #F4E0C9;
    color: #A08475;
}

.contact-form button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

.contact-form button:hover:disabled {
    background-color: #ddd;
}

.contact-icons {
    @apply flex flex-wrap justify-center space-x-8 mt-4;
}

.contact-item {
    @apply flex items-center space-x-2; /* Flex layout with space between icon and text */
    font-size: 0.875rem;
}

.contact-item i {
    @apply text-yellow-400 text-2xl;
}

.contact-item a {
    @apply text-sm text-gray-800 dark:text-gray-100 no-underline;
}

.contact-item a:hover {
    @apply underline;
}

.result-message {
    @apply mt-4 text-lg;
    color: #A08475; 
}

.wechat-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font: inherit;
}

.wechat-button:hover {
    text-decoration: underline;
}
