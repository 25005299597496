.project-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.project-image-container {
    position: relative;
    height: 380px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.project-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.project-description-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(249, 191, 69, 0.82);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, clip-path 0.5s ease-in-out, border-radius 0.5s ease-in-out;
    clip-path: circle(0% at 0% 0%);
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 8px;
}

.project-image-container:hover .project-image {
    transform: scale(1.1);
}

.project-image-container:hover .project-description-overlay {
    opacity: 1;
    clip-path: circle(150% at 50% 50%);
}

.project-body {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.project-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.project-description {
    text-align: justify;
    margin: 0;
}

.project-footer {
    margin-top: auto;
}

.slick-slide > div {
    height: 100%;
    display: flex;
}

.slick-slide > div > .project-card {
    height: 100%;
}
