.header-section {
    background-image: url('../../public/asset/image/background_1.jpg');
    background-size: cover;
    background-position: center;
    padding: 25px 0 3px 0 ;
}

.main-image {
    border-radius: 30px;
    margin: 0 auto;
    /*margin-top: 40px;*/
}

.icons-row {
    margin-top: 100px;
}
.fa-icon {
    margin: 0 10px;
}

.header-section h1 {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: 'Monaco', sans-serif;
}