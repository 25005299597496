@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100;
  transition: background-color 0.5s, color 0.5s;
}

.profile-picture {
  @apply w-24 h-24 bg-gray-400 rounded-full mx-auto;
}

.App-logo {
  @apply h-40;
}

.App-header {
  @apply bg-gray-800 min-h-screen flex flex-col items-center justify-center text-white;
}
.navbar-switch {
  @apply bg-white dark:bg-gray-800 p-4 justify-between items-center;
  transition: background-color 0.5s, color 0.5s;
}

.navbar-logo {
  @apply h-10 mr-2;
}

.project-card,
.blog-card {
  @apply bg-white dark:bg-gray-800 rounded-lg shadow p-4;
  transition: background-color 0.5s, color 0.5s;
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
}

.mt-16 {
  margin-top: 4rem;  /* Adjusted margin to prevent content overlap */
}
