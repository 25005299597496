footer {
    background-color: #343a40;
    color: #fff;
}

footer h5 {
    margin-bottom: 1rem;
}

footer p {
    margin: 0.5rem 0;
}

.social-icons a {
    color: #fff;
    margin: 0 0.5rem;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #007bff; /* Change color on hover */
}
